@tailwind base;
@tailwind components;
@tailwind utilities;

.remove-default-input-arrows::-webkit-outer-spin-button,
.remove-default-input-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.remove-default-input-arrows {
    -moz-appearance: textfield;
}

.MuiAutocomplete-popper .MuiAutocomplete-option {
    padding: 8px 12px;
    color: #475569;
    font-size: 13px;
    font-weight: 500;
}

.scan-body-elements {
    display: flex;
    flex-direction: column;
    padding: 4px 24px;
    color: #64748b;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.scan-body-elements.brief {
    padding: 0;
}

.scan-body-elements.brief ul li {
    display: none;
}

.scan-body-elements.brief ul li:nth-of-type(1),
.scan-body-elements.brief ul li:nth-of-type(2) {
    display: list-item;
}

.scan-body-elements ul {
    display: flex;
    flex-direction: column;
    list-style-type: disc;
    padding-left: 24px;
}

.scan-body-elements div {
    display: flex;
    flex-direction: column;
}

.custom-scroll::-webkit-scrollbar {
    width: 8px;
}
.custom-scroll::-webkit-scrollbar-track {
    background-color: transparent;
}
.custom-scroll::-webkit-scrollbar-thumb {
    /* background-color: theme.palette.primary[900] + 30, */
    border-radius: 10px;
}

@media print {
    * {
        overflow: visible !important;
    }
}
