.loading-circle {
    border: 1.5px solid #94a3b8;
    border-top: 1.5px solid transparent; /* Color of the moving part */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
